import { FC } from 'react'
import styles from './LegKitModal.module.scss'
import { LegKitModalProps } from './types'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { Img } from 'components/basic/Img'
import { VideoGif } from 'components/basic/VideoGif'

export const LegKitModalConnected = observer(() => {
	const { settingsStore } = useRootStore()

	return <LegKitModal />
})

export const LegKitModal: FC<LegKitModalProps> = (props) => {
	const {} = props

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.header}>
					<h2 style={{ textAlign: 'left' }}>The Base of Pod 4 Ultra fits seamlessly into your existing setup</h2>
					<div className={styles.sub_header}>
						Designed to fit between your current bed frame and your mattress, the Base does not replace your current setup. It is compatible with slatted and platform bed frames and foundations.
					</div>
				</div>
				<div>
					<Img
						alt={''}
						className={styles.hero_img}
						src={'https://eightsleep.imgix.net/assets/leg_kit_1_1314.png'}
					/>
				</div>
				<h3>Need extra support for your bed frame?</h3>
				<div>
					<VideoGif
						className={styles.smaller_img}
						src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ETS+web+04+withlegs.mp4'}
					/>
				</div>
				<div className={styles.description}>Add the optional leg kit to the Base for a more supportive setup</div>

				<div>
					<Img
						alt={''}
						className={styles.smaller_img}
						src={'https://eightsleep.imgix.net/assets/pod4-base-legs-outline_1608.png'}
					/>
				</div>
				<div className={styles.description}>You can also use the optional leg kit to use the Base in place of a bed frame</div>
			</div>
		</div>
	)
}
